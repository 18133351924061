import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Rater from "react-rater";
import SEO from "../components/seo";
import AppButton from "../components/common/button";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import TextareaInput from "../components/inputs/textareaInput";
import { changeRatingFormValue, clearRatingAndCommentFormAction, rateOrderAction } from "../state/rating.reducer";
import { getQueryParam } from "../utils/queryParams";
import "react-rater/lib/react-rater.css";
import { fetchOrderDataByIdAction } from "../state/order.reducer";
import createRipple from "../components/common/ripple";
import { clearLayoutAction, setLayoutAction } from "../state/layout.reducer";
import { LINKS } from "../constants/links";
import { AppContainer } from "../components/common/appContainer";

const StyledRestpayStarsWrapper = styled.div`
	margin: 0 auto;
	width: 80%;
	.react-rater {
		color: ${({ theme }) => theme.colors.brandBlue};
		display: flex;
		font-size: 50px;
		justify-content: center;
		width: 100%;
	}
	.react-rater-star {
		margin: -10px 5px;
	}
`;

const StyledInputWrapper = styled.div`
	margin: 50px 0 0;
`;

const StyledButtonsWrapper = styled.div`
	display: block;
	padding-bottom: 35px;
	padding-top: 15px;
	text-align: center;
`;

const StyledLinkButton = styled(AppButton)`
	border: none;
	color: ${({ theme }) => theme.colors.fontGray};
	font-size: 1rem;
	font-weight: 400;
	text-transform: lowercase;
	&.active,
	&:hover {
		background-color: #fff;
		&:hover,
		&:active {
			color: ${({ theme }) => theme.colors.fontGray};
		}
	}
`;

const StyledRatingsWrapper = styled.div`
	margin: -40px 0 15px;
`;

const StyledRatingDescription = styled.p`
	color: ${({ theme }) => theme.colors.fontGray};
	font-size: 16px;
	margin: 20px 0 0;
	text-align: center;
`;

const PaymentSuccess = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ratingReducer = useSelector((state) => state.rating);
	const orderReducer = useSelector((state) => state.order);
	const { body } = ratingReducer;
	const { data: tableData } = orderReducer;
	const { comment, foodRating, serviceRating, rating } = body;
	const isValid = foodRating > 0 && serviceRating > 0 && rating > 0;
	const withInvoice = getQueryParam("withInvoice") === "1";
	const orderId = getQueryParam("batchId");
	const logo = tableData?.restaurantTable?.restaurant?.images.find((image) => image.type === "LOGO")?.url || null;
	const slug = tableData?.restaurantTable?.restaurant?.slug || null;
	const [leaveOpinion, setLeaveOpinion] = useState(false);

	useEffect(() => {
		dispatch(clearRatingAndCommentFormAction());
		dispatch(fetchOrderDataByIdAction(orderId));
		setTimeout(() => {
			window.scrollTo(0, 0);
		});
	}, []);

	const onLeaveOpinionClick = (e) => {
		e.preventDefault();
		setLeaveOpinion(true);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(rateOrderAction(orderId, foodRating, serviceRating, rating, comment));
	};

	const handleChange = (name) => (value) => {
		dispatch(changeRatingFormValue(name, value));
	};

	useEffect(() => {
		dispatch(
			setLayoutAction({
				customLogoSrc: logo,
				customLogoUrl: slug ? `${LINKS.RESTAURANT}/${slug}` : null,
			})
		);
		return () => {
			dispatch(clearLayoutAction());
		};
	}, [logo]);

	return (
		<>
			<SEO title={t("paymentSuccess.title")} />
			<AppContainer>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<AppPageHeader>{t("paymentSuccess.header")}</AppPageHeader>
					<AppLeadingText>
						{t(withInvoice ? "paymentSuccess.descriptionWithInvoice" : "paymentSuccess.descriptionWithoutInvoice")}
					</AppLeadingText>
					<StyledRatingsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.foodRatingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("foodRating")(rating)}
								rating={foodRating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.serviceRatingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("serviceRating")(rating)}
								rating={serviceRating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.ratingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("rating")(rating)}
								rating={rating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
					</StyledRatingsWrapper>
					{leaveOpinion && (
						<StyledInputWrapper>
							<TextareaInput
								autoComplete="off"
								id="comment"
								inputChanged={handleChange("comment")}
								placeholder={t("paymentSuccess.inputPlaceholder")}
								type="text"
								value={comment}
							/>
						</StyledInputWrapper>
					)}
					<StyledButtonsWrapper>
						<AppButton as="button" disabled={!isValid}>
							{t("common.send")}
						</AppButton>
						{!leaveOpinion && (
							<StyledLinkButton onClick={onLeaveOpinionClick}>{t("paymentSuccess.leaveOpinion")}</StyledLinkButton>
						)}
					</StyledButtonsWrapper>
				</form>
			</AppContainer>
		</>
	);
};

export default PaymentSuccess;
